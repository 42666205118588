import React from "react"
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import StrategyCallContent from "../components/StrategyCallContent/index";

function StrategyCall({location}) {
  return(
    <div>
      <Helmet bodyAttributes={{ class: 'whiteHeader whiteHeader-show' }}>
        <title>Voa Labs | Strategy Call</title>
        <meta name="description" content="Voa Labs strategizes, designs & builds digital experiences. We are a digital product studio from Switzerland." />
      </Helmet>
      <StrategyCallContent lang="de" location={location} />
    </div>
  )
}

StrategyCall.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default StrategyCall;